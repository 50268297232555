import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-green-400 to-blue-500">
      <div className="text-center p-8">
        <h1 className="text-5xl font-bold text-gray-800 mb-4">Coming Soon</h1>
        <p className="text-gray-600 text-lg">
          We're working hard to bring something amazing to you. Stay tuned!
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
